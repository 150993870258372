
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';

const styles = (theme) => ({
    ...theme.customStyle,
    footerLink: {
        fontSize: 15,
        fontFace: 'Mark',
        fontWeight: 'normal',
        color:'#0b002b',
        textAlign: 'right',
        margin: '20px auto',
        paddingBottom: '30px',
        '& a': {
            margin: '0px 0.5em',
            textDecoration: 'none',
            color: 'inherit',
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
            },
        },
    },
})

function Footer(props) {
    const { classes } = props
    return (
        <div className="footer-container">
            <div className="container footerized">
                <div className="logo"></div>

                <div className={classes.footerLink}>

                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={8}>
                            <Link to='/terms'>Terms of Service</Link>
                        </Grid>

                        <Grid item xs={12} sm={2} className="md-center">
                            <Link to='/privacy'>Privacy Policy</Link>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <a href="http://www.aptitudemedical.com" rel="noreferrer" target="_blank">&copy; Aptitude Labs, {(new Date()).getFullYear()}</a>
                        </Grid>
                    </Grid>

                    {/* <Link to='/terms'>Terms of Service</Link> */}
                    {/* <Link to='/privacy'>Privacy Policy</Link> */}
                    {/* <a href="http://www.aptitudemedical.com" rel="noreferrer" target="_blank">&copy; Aptitude Labs, 2022</a> */}
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(Footer)
