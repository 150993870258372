import  {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ImageUploadButton,{handleImageCompression} from '../Buttons/ImageUploadButton';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {connect} from 'react-redux';
import Paper from '@mui/material/Paper'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import {useMutation} from 'react-query'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const sendFeedBack = async (data) => {
    return (await (axios.post('/data/userFeedback', data))).data
}


function FeedBack({state}) {    
    const [showFeedBackBtn, setShowFeedBackBtn] = useState(localStorage.getItem('showFeedBackBtn') !== 'false');
    const [btnSize, setBtnSize] = useState('normal');
    const [drawer,setDrawer] = useState(false)
    const [feedback,setFeedback] = useState({type:'',files:[],message:'',name:'',email:''})
    const [compressionProgress, setCompressionProgress] = useState(null)
    const [imageAreaFocus,setImageAreaFocus] = useState(0)
    const isMobile = useMediaQuery('(max-width:600px)')
    const handleChange = (e) => {
        if (sendMutation.isSuccess) {
            return
        }
        setFeedback((f)=>({...f, [e.target.name]:e.target.value}))
    }

    const sendMutation = useMutation(sendFeedBack)

    const handleSubmit = ()=>{        
        const formData = new FormData()
        Object.keys(feedback).forEach(f=>{
            if (f==='files') {
                feedback[f].forEach(({file})=>{
                    formData.append('file', file)
                }
                )                    
            } else {
                formData.append(f, feedback[f])
            }
        })
        formData.append('url', window.location.href)
        formData.append('userAgent', window.navigator.userAgent)
        formData.append('reduxState', JSON.stringify(state))        
        sendMutation.mutate(formData)
    }

    const handleImageDrop = async (e)=>{
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.files.length && feedback.files.length<5) {
            const file = e.dataTransfer.files[0]
            const compressedFile = await handleImageCompression(file,null,onCompressionProgress)
            if (compressedFile) {
                setFeedback((f)=>({...f,files:f.files.concat(compressedFile)}))
            }
        }
    }

    const handleImagePaste = async e=>{
        e.preventDefault()
        e.stopPropagation()   
        if (e.clipboardData.files.length && feedback.files.length<5) {
            const file = e.clipboardData.files[0]
            const compressedFile = await handleImageCompression(file,null,onCompressionProgress)
            if (compressedFile) {
                setFeedback((f)=>({...f,files:f.files.concat(compressedFile)}))
            }
        }
        
    }

    const onCompressionProgress = (progress)=>{
        setCompressionProgress(progress)
    }

    const handleSetShowFeedBackBtn = (input)=>{
        setShowFeedBackBtn(input)
        localStorage.setItem('showFeedBackBtn',input)
    }

    useEffect(()=>{
        if (isMobile) {
            setBtnSize('small')
        } else {
            setBtnSize('normal')
        }
    },[isMobile])
    


    
    const feedbackIsValid = feedback.type && feedback.message.trim()
  
  return (
      <div>
          {
              showFeedBackBtn ? (btnSize==='normal' ? 
              <div style={{position: 'fixed',right:'-47px',top:'20vh',transform:'rotate(-90deg)',zIndex:900}}>
              <Button style={{textTransform:'none',borderRadius:'0px',padding:'2px 10px'}} variant='contained' color='primary' onClick={()=>{setDrawer(true)}}>
                    <Typography variant="subtitle2">
                        Feedback
                    </Typography>
              </Button>
              <Button style={{minWidth:'36px',padding:'0px'}} onClick={()=>{handleSetShowFeedBackBtn(false)}} >
                  <CloseIcon/>
              </Button>
              </div> 
              :  
              <div style={{position: 'fixed',right:'-37px',top:'20vh',transform:'rotate(-90deg)',zIndex:900}}>
              <Button style={{textTransform:'none',borderRadius:'0px',padding:'2px 10px'}} variant='contained' color='primary' onClick={()=>{setDrawer(true)}}>
                    <Typography variant="subtitle2" style={{fontSize:'10px'}} >
                        Feedback
                    </Typography>
              </Button>
              <Button style={{minWidth:'26px',padding:'0px'}} onClick={()=>{handleSetShowFeedBackBtn(false)}} >
                  <CloseIcon fontSize='small'/>
              </Button>
              </div>) :  <div style={{position: 'fixed',right:'0px',top:'20vh'}}>
              <Button style={{minWidth:'26px',padding:'10px 0px'}} onClick={()=>{handleSetShowFeedBackBtn(true);setDrawer(true)}}>
                <ChevronLeftIcon style={{fontSize:'26px'}} color=''/>
              </Button>           
              </div>
          }

        <Drawer anchor='right' open={drawer} onClose={()=>setDrawer(false)} style={{zIndex:1900}}>
            <Paper style={{padding:'0.2em 1em 59px',maxWidth:'90vw', width:'600px'}} elevation={0}>
            <div style={{display:'flex',alignItems:'center',alignContent:'center', justifyContent:'space-between', margin:'1em 0.2em' }}>
                <Typography variant="h5" >
                    We'd love your feedback!
                </Typography>
          <IconButton onClick={()=>{setDrawer(false)}} size="large">
            <CloseIcon />
          </IconButton>
          </div>

        <Typography variant='h6' style={{marginTop:'1em',fontSize:'1.3em'}}>What type of feedback are you leaving today?</Typography>

        <FormControl component="fieldset">
          <RadioGroup  name="type" value={feedback.type} onChange={handleChange}>
            <FormControlLabel value="website" control={<Radio color='default' />} label="The Aptitude Labs website" />
            <FormControlLabel value="samplecollection" control={<Radio color='default'/>} label="The sample collection and testing process"/>
            <FormControlLabel value="other" control={<Radio color='default'/>} label="Other" />        
          </RadioGroup>
        </FormControl>

        <Typography variant='h6' style={{marginTop:'1em',fontSize:'1.3em'}}>What would you like to share about your experience?</Typography>

        <TextField
            multiline
            rows={6}
            variant="outlined"
            name='message'
            style={{width:'95%',margin:'0.5em 2.5%'}}
            value={feedback.message}
            onChange={handleChange}
        />

    <Typography variant='h6' style={{marginTop:'1em',fontSize:'1.3em'}}>Please attach any image or screenshot related to your feedback.</Typography>
    <Typography variant='subtitle1' style={{marginLeft: '1em',fontSize:'1em'}}>Only support .png, .jpeg, .jpg, .bmp files.</Typography>
    <ImageUploadButton                            
        variant="contained"
        color="primary"    
        onFile={(file)=>{        
            setFeedback((f)=>({...f,files:f.files.concat(file)}))
        }}                            
        onCompressionProgress={onCompressionProgress}
        startIcon={<PhotoCameraIcon />}
        disabled={feedback.files.length >= 5}
        style={{ margin: '1em', textTransform:'none'  }}    
    >
        Add Image or Take Photo
    </ImageUploadButton>

    <Typography style={{ marginLeft: '1em'}}>Or you can paste image from clipboard or drop files below:</Typography>


    {
    compressionProgress &&
    <Typography style={{  padding: 5 }} color='primary'>
        Compressing image files {compressionProgress} %, please wait...
    </Typography>
    }
    {feedback.files.length >= 5 && (
        <Typography style={{ color: 'red', padding: 5 }}>
            You can only upload up to 5 images. Remove
            unwanted images to add new.
        </Typography>
    )}

        {feedback.files.length >= 0 && (
            <div
                contentEditable={"true"}
                suppressContentEditableWarning    
                onKeyDown={(e)=>{
                    if (e.key.toLowerCase()==='v' && (e.ctrlKey || e.metaKey)) {

                    } else {
                        e.preventDefault()
                        e.stopPropagation()
                    }                          
                }}
                style={{
                    border: imageAreaFocus===2? '2px solid #217679' :(imageAreaFocus===1?'1px solid #000':'1px solid #CCCCCC') ,
                    padding: imageAreaFocus===2?'0px':'1px',
                    borderRadius: 5,
                    maxWidth: '90%',
                    margin: '5px 2.5%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around'                      
                }}
                tabIndex={0}
                onDrop={handleImageDrop}
                onDragOver={(e)=>{              
                    setImageAreaFocus(2)
                    e.preventDefault()
                }}
                onDragLeave={(e)=>{
                    setImageAreaFocus(0)
                }}           
                onPaste={handleImagePaste}            
                onFocus={() => {setImageAreaFocus(2)}}
                onBlur={() => {setImageAreaFocus(0)}}
                onMouseEnter={()=>{imageAreaFocus===0 && setImageAreaFocus(1)}}
                onMouseLeave={()=>{if (imageAreaFocus!==2) setImageAreaFocus(0)}}
            >
                 {feedback.files.length===0 && 
                 <Typography style={{ padding: '25px 5px' , textAlign:'center'}}>
                    Paste or drop image here
                    </Typography>
                 }


                {feedback.files.map((i, idx) => (
                    <div
                        key={idx}
                        style={{
                            position: 'relative',
                            display: 'block',
                            margin: '10px',                        
                            boxShadow: '0px 0px 5px #CCCCCC',
                            padding:'12px'
                        }}
                    >
                        <img
                            key={idx}
                            src={i.url}
                            style={{                            
                                maxWidth: '125px',
                                minHeight: '50px',
                            }}
                            alt={i.file.name}
                        ></img>
                        
                        <Button
                            color='secondary'
                            onClick={() => {

                                setFeedback((f) => ({
                                    ...f,files:f.files.filter((_, i) => i !== idx)
                                }))                            
                            }}
                            style={{
                                position: 'absolute',
                                right:'0px',
                                top:'0px',
                                minWidth: '22px',
                                padding: '0px',
                                height: '22px',
                                borderRadius: '50%',
                            }}
                            variant='contained'
                        >
                            <CloseIcon fontSize='small'/>
                        </Button>
                    </div>
                ))}
            </div>
        )}



    <Typography variant='h6' style={{marginTop:'1em',fontSize:'1.3em'}}>Please leave your name and email if you want to hear back from us.</Typography>
    <TextField
        label="Name"
        name='name'
        value={feedback.name}
        onChange={handleChange}
        variant="outlined"
        style={{width:'90%',margin:'1em 2.5%'}}
    />
    <TextField
        label="Email"
        name='email'
        value={feedback.email}
        onChange={handleChange}
        variant="outlined"
        type={'email'}
        style={{width:'90%',margin:'1em 2.5%'}}
    />

        <div style={{textAlign:'center'}}>
        
        <Button fullWidth variant='contained' 
            onClick={handleSubmit}
            color='primary'
            style={{maxWidth:'300px',margin:'1em auto',padding:'1em',textTransform:'none',borderRadius:'50px'}}
            disabled={sendMutation.isLoading || sendMutation.isSuccess || (!feedbackIsValid)}
        >
            <Typography variant='h6'>Submit</Typography>
        {sendMutation.isLoading && <CircularProgress style={{position:'absolute'}}/>}
        </Button>
        {
            !feedbackIsValid && <Typography variant='subtitle2'>
                Please fill out the feedback above
            </Typography>
        }
        {
            sendMutation.isSuccess && <Typography variant='subtitle2' color='primary'>
                Thank you for your feedback!
            </Typography>
        }
        </div>
        
        </Paper>
        </Drawer>
      </div>
  );
}



const mapStateToProps = (state) => ({state})

export default connect(mapStateToProps)(FeedBack);
